import React, { useState, useContext, useEffect } from "react"
import {
  NavBarContainer,
  Logo,
  CloseButton,
  CloseMessage,
  CloseButtonWrapper,
  PhoneButton,
  ButtonsWrapper,
} from "./style"
import JCHLogo from "images/jch-logo.png"
import { CSSTransition } from "react-transition-group"
import useMedia from "hooks/useMedia.js"
import { PopUpContext } from "context/PopUpContext.js"
import { ProgressBar } from "../ProgressBar"
import { Icon } from "utils/getIcon"
import { ReactComponent as Phone } from "images/icons/phone.svg"
import getOS from "helpers/getOS"

const NavBar = ({ cover, actionPlan }) => {
  const closeIcon = <Icon name="cross" />
  const phoneIcon = <Phone />
  const { togglePopUp, isPopUpOpen, popUpType } = useContext(PopUpContext)
  const [shove, setShove] = useState(false)

  const isDesktop = useMedia(
    // Media queries
    ["(min-width: 1024px)"],
    //options
    [true],
    // default
    false
  )

  const operatingSystem = getOS()
  const windows = operatingSystem === "Windows"

  useEffect(() => {
    if (
      windows &&
      isPopUpOpen &&
      (popUpType === "longForm" || "getHelp" || "international")
    ) {
      setShove(true)
    } else {
      setShove(false)
    }
  }, [windows, isPopUpOpen, popUpType])

  // useEffect(() => {
  //   console.log("shove:", shove)
  // }, [shove])

  const getHelpContent = `
  <div class="getHelp">
      <h3 class >If you’re looking for help, you can call:</h3>
      <ul >
        <li>
          <span>1800 RESPECT</span> <a href="tel:1800 737 732">
          1800 737 732
          </a>
        </li>
        <li>
          <span>Lifeline</span> <a href="tel:13 11 14">
          13 11 14
          </a>
        </li>
        <li>
        <span>Sexual Assault Crisis Line</span> <a href="tel:1800 806 292">
        1800 806 292
        </a>
      </li>
        <li>
        <span>QLife</span> <a href="tel:1800 184 627">
        1800 184 627
        </a>
      </li>

      <li>
      <span>InTouch Multicultural Centre Against Family Violence</span> <a href="tel:1800 755 988">
      1800 755 988
      </a>
    </li>

    <li>
    <span>Safer Community Program</span>
    <a href="tel:61 3 9035 8675">61 3 9035 8675</a>
    <a rel="noopener noreferrer" target="_blank" href="https://safercommunity.unimelb.edu.au/">safercommunity.unimelb.edu.au</a>
  </li>


      
      </ul>
  </div>
  `

  return (
    <NavBarContainer cover={cover} id="nav">
      <CSSTransition
        in={true}
        appear={cover ? false : true}
        timeout={1000}
        classNames="fade"
      >
        <ProgressBar cover={cover} actionPlan={actionPlan} />
      </CSSTransition>

      <CSSTransition
        in={true}
        appear={cover ? false : true}
        timeout={1000}
        classNames="fade"
      >
        <Logo cover={cover ? "true" : "false"} to="/">
          <img src={JCHLogo} alt="" />
        </Logo>
      </CSSTransition>
      <CloseButtonWrapper shove={shove}>
        <ButtonsWrapper>
          <PhoneButton
            label={isDesktop ? `Get Help` : "Help"}
            icon={phoneIcon}
            noNewTab
            iconRight
            onClick={() => togglePopUp(true, "getHelp", null, getHelpContent)}
          />

          <CloseButton
            label={isDesktop ? `Quick Exit` : "Exit"}
            icon={closeIcon}
            link="https://www.weather.com.au/"
            noNewTab
            iconRight
          >
            <CloseMessage cover={cover}>
              <h3>
                To leave this site quickly, click the ‘Quick Exit’ button.
              </h3>
              <p>
                If you feel like you are in danger, please call 000 immediately.
              </p>
            </CloseMessage>
          </CloseButton>
        </ButtonsWrapper>
      </CloseButtonWrapper>
    </NavBarContainer>
  )
}

export default NavBar
