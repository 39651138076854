import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { Button } from "components/Button"
import { IconWrapper } from "components/Button/style.js"
import { Link } from "gatsby"
export const NavBarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: flex-end;
  width: 100%;
  margin: 0 auto;
  max-width: 1400px;
  /* padding: ${props =>
    props.cover ? "11px 20px 20px 10px" : "15px 20px"}; */
  padding: 15px 20px;
  position: ${props => (props.cover ? "fixed" : "relative")};
  transform: ${props => props.cover && "translateX(-50%);"};
  left: ${props => props.cover && "50%"};
  z-index: 2;
  height:86px;

  @media ${device.desktop} {
    align-items: center;
    /* padding: ${props => (props.cover ? "20px" : "25px 20px")}; */
    padding: 4px 20px 0 20px;
    height: 130px;
  }

  :after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    background: white;
    transition: opacity 400ms;
    transition-delay: 600ms;
    opacity: ${props => (props.cover ? 0 : 0.2)};

 
  }
`

export const Logo = styled(Link)`
  transition: opacity 400ms;
  opacity: ${props => (props.cover === "true" ? 0 : 1)};

  margin: 0 auto;
  transition: 120ms;
  pointer-events: ${props => props.cover === "true" && "none"};

  img {
    width: 110px;
    height: 50px;
    display: block;
    @media ${device.desktop} {
      width: 140px;
      height: 64px;
    }
  }

  :hover {
    opacity: 0.7;
  }
`
export const CloseButtonWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  margin: 0 auto;
  max-width: 1400px;
  padding: 5px 8px;
  justify-content: flex-end;
  transform: translateX(-50%);
  z-index: 2;
  /* height: 86px; */
  pointer-events: none;

  @media ${device.desktop} {
    padding: 4px 20px 0 20px;
    height: 130px;
    padding-right: ${props => props.shove && "28px"};
  }
`
export const CloseButton = styled(Button)`
  font-family: "Azo Sans", "Arial", "sans-serif";
  text-transform: uppercase;
  padding: 0;
  position: relative;
  pointer-events: all;
  flex-direction: column;
  background: none;
  font-size: 8px;
  height: auto;
  z-index: 4;
  :hover {
    background: none;
  }

  :last-of-type {
    margin-left: 13px;
  }

  :after {
    content: "";
    background: #2d77be;
    border-radius: 3px;
    position: absolute;
    bottom: 2px;
    width: 30px;
    height: 13px;
    z-index: -2;

    @media ${device.desktop} {
      display: none;
    }
  }
  @media ${device.desktop} {
    margin-top: 0px;
    flex-direction: row;
    font-size: 12px;
    padding-top: 2px;
    width: 118px;
    height: 34px;
    align-self: center;
    align-items: center;
    border-radius: 17px;
    opacity: 1;
    transition: background 120ms;
    background: #dd0067;
    border: 2px solid white;

    :hover {
      background: #ad0a56;
    }
  }

  ${IconWrapper} {
    margin: 0 auto;
    order: -1;
    /* padding: 12px; */
    width: 42px;
    height: 42px;
    display: flex;
    margin-bottom: 7px;
    transition: background 120ms;
    background: #dd0067;
    border: 2px solid white;
    border-radius: 50%;

    :hover {
      background: #ad0a56;
    }
    @media ${device.desktop} {
      padding: 0;
      margin: 0;
      order: 2;
      width: auto;
      height: auto;
      background: none;
      border: none;
      border-radius: 50%;
      margin-top: -2px;
      margin-left: 5px;
      :hover {
        background: none;
      }
    }

    svg {
      height: 13px;
      width: 13px;

      @media ${device.desktop} {
        height: 10px;
        width: 10px;
        margin-left: 8px;
      }
    }
  }
`
export const CloseMessage = styled.div`
  position: absolute;
  display: none;
  right: 20px;
  bottom: -13px;
  right: 0;
  width: 370px;
  background: white;
  border-radius: 5px;
  transform: translateY(100%);
  padding: 40px;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);

  @media ${device.desktop} {
    display: block;
  }
  h3 {
    font-size: 16px;
    color: #c3015c;
    font-weight: 600;
    margin-bottom: 28px;
    font-family: "Adelle", "Times New Roman", "serif";
    text-transform: none;
    text-align: left;
  }

  p,
  li {
    color: #4a5a80;
    font-size: 16px;
    text-transform: none;
    font-weight: 400;
    text-align: left;
    font-family: "Adelle", "Times New Roman", "serif";
  }

  li {
    list-style: none;
    margin-bottom: 15px;
    position: relative;
    padding-left: 19px;

    a {
      color: inherit;
      pointer-events: all;

      :hover {
        text-decoration: underline;
      }
    }
    :last-of-type {
      margin: 0;
    }

    :before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate3D(0, -50%, 0);
      width: 8px;
      height: 8px;
      background: #aaa1d5;
      border-radius: 50%;
    }
    span {
      font-weight: 600;
      margin-right: 27px;
      position: relative;

      :before {
        content: "";
        position: absolute;
        top: 50%;
        top: 50%;
        right: -5px;
        transform: translate3D(100%, -50%, 0);
        width: 20px;
        height: 1px;
        background: #4a5a80;
      }
    }
  }

  ${CloseButton}:hover & {
    opacity: 1;
    pointer-events: all;
  }
`

export const PhoneButton = styled(CloseButton)`
  svg {
    width: 16px !important;
    height: 16px !important;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  padding: 9px 12px 12px 12px;
  /* background: #ffffff;
  border-radius: 4px; */

  @media ${device.desktop} {
    padding: 0;
    /* background: none; */
  }
`
