import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { Button } from "components/Button"
import { IconWrapper } from "../Button/style.js"

import { StandardGrid, TextWrapper } from "components/reusableComponents"

export const CoverPageContainer = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 30px;

  @media ${device.desktop} {
    padding-top: 130px;
  }
`
export const CoverPageWrapper = styled.div`
  width: 100%;
  /* position: relative; */
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
`

export const MelbourneUniLogo = styled.div`
  display: block;
  position: absolute;
  top: 10px;
  padding-left: 10px;
  width: 100%;
  max-width: 1400px;
  left: 50%;
  transform: translateX(-50%);
  @media ${device.desktop} {
    top: 20px;
    padding-left: 20px;
  }

  img {
    width: 60px;
    height: 60px;

    @media ${device.desktop} {
      width: 110px;
      height: 110px;
    }
  }
`
export const CoverTwoGrid = styled(StandardGrid)`
  margin-bottom: 60px;
  align-items: center;
  justify-items: center;
  @media ${device.desktop} {
    grid-gap: 30px;
    margin-bottom: 60px;
  }

  @media ${device.large} {
    grid-gap: 45px;
    margin-bottom: 100px;
  }

  img {
    width: 420px;
    order: 2;

    @media ${device.desktop} {
      order: 1;
    }
  }
`

export const CoverTextSection = styled(TextWrapper)`
  order: 1;
`
export const InitialSection = styled.div`
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-row-gap: 40px;
  max-width: 1350px;
  margin-bottom: 60px;
  width: ${props => `calc(100% - 2*${props.theme.mobileGutter})`};
  align-items: center;
  @media ${device.desktop} {
    grid-template-columns: 41% 1fr;
    grid-template-rows: 1fr;
    grid-gap: 30px;
    align-self: center;
    width: ${props => `calc(100% - 2*${props.theme.desktopGutter})`};

    @media (min-height: 1000px) {
      > * {
        padding-top: 150px !important;
      }
    }
  }

  @media ${device.large} {
    grid-gap: 30px;
  }
`

export const CoverBackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  img {
    display: block;
    width: 140%;
    max-width: 300%;
    min-height: 50%;
    height: 2400px;

    @media ${device.desktop} {
      width: 100%;
      height: 2000px;
    }
  }
`

export const CoverBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  @media ${device.desktop} {
    padding: 0;
  }
`

export const Logo = styled.img`
  display: ${props => (props.cover ? "none" : "block")};
  width: 170px;
  height: 75px;
  margin-bottom: 15px;

  @media ${device.desktop} {
    width: 361px;
    height: 164px;
    margin-bottom: 20px;
  }
`

export const Tags = styled.div`
  display: inline-grid;
  justify-content: flex-start;
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
  margin-bottom: 35px;
  @media ${device.desktop} {
    grid-gap: 29px;
    margin-bottom: 55px;
  }
  h3 {
    font-size: 11px;
    color: white;
    position: relative;

    @media (min-width: 330px) {
      font-size: 12px;
    }
    @media ${device.desktop} {
      font-size: 16px;
    }

    :after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      height: 12px;
      width: 1px;
      right: -10px;
      background: white;
      @media ${device.desktop} {
        right: -15px;
        height: 17px;
        width: 2px;
      }
    }

    :last-of-type {
      :after {
        display: none;
      }
    }
  }
`
export const CoverText = styled.p`
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 150%;
  margin-bottom: 25px;
  max-width: 387px;
  padding: 0 20px;

  @media ${device.desktop} {
    font-size: 24px;
    margin-bottom: 50px;
    max-width: 530px;
  }
`

export const GetStartedButton = styled(Button)`
  font-size: 16px;
  color: white;
  background: #3b10b1;
  border-radius: 5px;
  width: 165px;
  font-weight: 500;
  height: 60px;
  font-family: "Azo Sans", "Arial", "sans-serif";

  @media ${device.desktop} {
    font-size: 20px;
    width: 276px;
    height: 64px;
  }

  :hover {
    background: #2e0b8e;
  }
`
export const BottomButton = styled(GetStartedButton)`
  margin-top: 0px;

  @media ${device.desktop} {
    margin-top: 35px;
  }
`

export const FurtherInfo = styled.div`
  margin: 0 auto;
  z-index: 1;

  width: ${props => `calc(100% - 2*${props.theme.mobileGutter})`};

  padding-top: 0px;

  @media ${device.desktop} {
    padding-top: 188px;

    width: ${props => `calc(100% - 2*${props.theme.desktopGutter})`};
  }
`

export const ScrollButton = styled(Button)`
  margin-top: 25px;
  flex-direction: column;
  font-family: "Azo Sans", "Arial", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  opacity: 1;

  @media ${device.desktop} {
    font-size: 16px;
    margin-top: 30px;
  }

  ${IconWrapper} {
    margin: 0;
    transform: translateY(6px);
    order: 2;
    border-radius: 50%;
    border: 2px solid white;
    height: 20px;
    width: 20px;

    @media ${device.desktop} {
      transform: translateY(10px);
    }
    svg {
      height: 7px;
      transform: rotate(-90deg);
      g,
      path {
        fill: white;
      }
    }
  }

  :hover {
    opacity: 0.7;
  }
`
export const FadedText = styled.div`
  text-align: center;
  margin-top: 120px;
  margin-bottom: 120px;
  position: relative;
  @media ${device.desktop} {
    margin-top: 170px;
    margin-bottom: 170px;
  }
  @media ${device.large} {
    margin-top: 220px;
    margin-bottom: 250px;
  }

  h2 {
    margin-bottom: 30px;
    font-size: 30px;
    /* line-height: 113%; */
    z-index: 3;
    @media ${device.desktop} {
      margin-bottom: 49px;
      font-size: 42px;
    }
    @media ${device.large} {
      margin-bottom: 60px;
      font-size: 48px;
    }
  }

  img.purple {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: -1;
    opacity: 0.8;
    max-width: 200%;
    width: 120%;
    height: 350%;
  }
`

export const CoatWraper = styled(Button)`
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  background: #fff;
  border-radius: 0 0 10px 0;
  width: 78px;
  height: 96px;

  :hover {
    opacity: 0.8;
    background: #fff;
  }

  img {
    width: 40px;
    height: 60px;
  }
  @media ${device.desktop} {
    width: 118px;
    height: 136px;
    img {
      width: 66px;
      height: 98px;
    }
  }
`
